.avatar-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: transparent;
    transition: ease-in-out .2s;


    &:hover{
        background-color: rgba(0,0,0,0.6);
        color: white;
    }
}

// scrool do phone selector
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 2px;
  }
  
  ::-webkit-scrollbar {
    width: 8px;
  }
  