@media screen and (max-width: 500px) {
  .animated-paragraph {
    width: 90%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.root_main {
  display: flex;
  flex-direction: column;
  padding: 0 0.625rem;
  max-height: 100vh;
  padding-bottom: 16px;
  overflow-y: scroll;
  position: relative;

  @media only screen and (min-width: 1200px) {
    padding: 0 1.625rem;
  }
}
.root_main::-webkit-scrollbar {
  display: none;
}
