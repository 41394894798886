* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  min-height: 100%;
}
