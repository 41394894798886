.navigation-controls {
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-top: 8px;
}

.navigation-button {
  cursor: pointer;
}

.react-pdf__Document {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.pdf-container {
  overflow: auto;
}
