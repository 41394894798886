.menu-container {
  .MuiListItemButton-root {
    border-radius: 6px;
    padding: 0.775rem;
    font-weight: 500;
    font-size: 0.875rem;
  }
  height: calc(100% - 120px);
  overflow: scroll;
}

.menu-container::-webkit-scrollbar {
  display: none;
}

.menu-selected {
  background: linear-gradient(
    to left,
    rgba(126, 56, 218, 1),
    rgba(88, 45, 145, 0.3)
  );
  color: #fff !important;
}

.menu-selected-dark {
  background: linear-gradient(
    to left,
    rgba(0, 178, 175, 1),
    rgba(0, 178, 175, 0.3)
  );
  color: #fff !important;
}

.menu-icon-selected {
  color: #fff !important;
  font-weight: 700 !important;
}

.parent-active-dark {
  background-color: rgba(211, 211, 211, 0.1);
}

.parent-active-light {
  background-color: rgba(211, 211, 211, 0.3);
}
